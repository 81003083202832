import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { BASE_URL } from "../../config";
import { CSVLink } from "react-csv";
import { useAuth } from "../../Context/AuthContext";
import { use } from "react";

const Polls = () => {
  const [polls, setPolls] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [totalPolls, setTotalPolls] = useState([]);
  const [formData, setFormData] = useState({
    question: "",
    category: "",
    postId: "",
    options: {
      A: { value: "", votes: 0 },
      B: { value: "", votes: 0 },
      C: { value: "", votes: 0 },
      D: { value: "", votes: 0 },
    },
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const { userInfo } = useAuth();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchPolls();
  }, [currentPage, rowsPerPage]);


  const fetchTotalPolls = () => {
    fetch(`${BASE_URL}/mm/all-questions`)
      .then((response) => response.json())
      .then((data) => {
        const pollsWithVotes =
          data?.data.map((poll) => {
            const totalVotes = Object.values(poll.options).reduce(
              (acc, option) => acc + option.votes,
              0
            );
            return { ...poll, totalVotes };
          }) || [];
        setTotalPolls(pollsWithVotes);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchTotalPolls();
  }, []);

  const fetchPolls = () => {
    setLoading(true);
    fetch(`${BASE_URL}/mm/all-questions?page=${currentPage}&size=${rowsPerPage}`)
      .then((response) => response.json())
      .then((data) => {
        const pollsWithVotes =
          data?.data.map((poll) => {
            const totalVotes = Object.values(poll.options).reduce(
              (acc, option) => acc + option.votes,
              0
            );
            return { ...poll, totalVotes };
          }) || [];
        setPolls(pollsWithVotes);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const filteredPolls = polls.filter(
    (poll) =>
      poll.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      poll.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      Object.values(poll.options)
        .map((option) => option.value)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      poll.totalVotes.toString().includes(searchTerm.toLowerCase())
  );

  const handleDelete = (poll) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this poll?"
    );
    if (confirmDelete) {
      const pollId = poll.pollId;
      fetch(`${BASE_URL}/mm/delete?type=poll&id=${pollId}`, {
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            setAlert({
              show: true,
              message: "Poll deleted successfully",
              type: "success",
            });
            setTimeout(() => {
              setAlert({ show: false, message: "", type: "" });
              fetchPolls();
              fetchTotalPolls();
            }, 1000);
          } else {
            alert("Failed to delete poll");
          }
        })
        .catch((error) => {
          console.error("Error deleting poll:", error);
          setAlert({
            show: true,
            message: "Error deleting poll. Please try again.",
            type: "danger",
          });
          setTimeout(() => {
            setAlert({ show: false, message: "", type: "" });
          }, 1000);
        });
    }
  };

  const headers = [
    { label: "S.No", key: "sno" },
    { label: "Category Name", key: "category" },
    { label: "Question", key: "question" },
    { label: "Option A", key: "optionA" },
    { label: "Option B", key: "optionB" },
    { label: "Option C", key: "optionC" },
    { label: "Option D", key: "optionD" },
    { label: "Total Votes", key: "totalVotes" },
    { label: "Post ID", key: "id" },
  ];

  const csvData = polls.map((poll, index) => ({
    sno: index + 1,
    category: poll.category,
    question: poll.question,
    optionA: poll.options.A.value,
    optionB: poll.options.B.value,
    optionC: poll.options.C.value,
    optionD: poll.options.D.value,
    totalVotes: poll.totalVotes,
    id: poll.postId,
  }));

  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetch(`${BASE_URL}/mm/${isEdit ? "edit-question" : "add-question"}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === 200) {
          setAlert({ show: true, message: data.message, type: "success" });
          setTimeout(() => {
            setAlert({ show: false, message: "", type: "" });
            setShowModal(false);
            setFormData({
              question: "",
              category: "",
              postId: "",
              options: {
                A: { value: "", votes: 0 },
                B: { value: "", votes: 0 },
                C: { value: "", votes: 0 },
                D: { value: "", votes: 0 },
              },
              // status: "active",
            });
            setIsEdit(false);
          }, 1000);
          fetchPolls();
        } else {
          alert("Failed to save poll");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleEditPoll = (poll) => {
    setFormData({
      question: poll.question,
      category: poll.category,
      postId: poll.postId,
      options: poll.options,
      //   status: poll.status,
      pollId: poll.pollId,
    });
    setShowModal(true);
    setIsEdit(true);
  };

  const handlePageSizeChange = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const searchFunction = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    // setNoDataMessage("");
    // setFilteredRssData(value);
    try {
      if (value !== "") {
        const filtered = filtermethod(value);
        console.log(filtered);
        if (filtered.length === 0) {
          // setNoDataMessage("No matching rss found.");
        }
        setPolls(filtered);
      } else {
        fetchPolls();
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const filtermethod = (value) => {
    return totalPolls.filter((poll) => {
      const question = poll.question ? poll.question.toLowerCase() : "";
      const category = poll.category ? poll.category.toLowerCase() : "";
      const options = poll.options
        ? Object.values(poll.options)
            .map((option) => option?.value || "")
            .join(" ")
            .toLowerCase()
        : "";
      const totalVotes = poll.totalVotes !== undefined ? poll.totalVotes.toString() : "";
  
      return (
        question.includes(value) ||
        category.includes(value) ||
        options.includes(value) ||
        totalVotes.includes(value)
      );
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex justify-content-between align-items-center mt-3">
          <h3 className="fs-4">Polls</h3>
          {userInfo?.roletype === 0 ? (
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              setFormData({
                question: "",
                category: "",
                postId: "",
                options: {
                  A: { value: "", votes: 0 },
                  B: { value: "", votes: 0 },
                  C: { value: "", votes: 0 },
                  D: { value: "", votes: 0 },
                },
                // status: "active",
              });
              setIsEdit(false);
              setShowModal(true);
            }}>
            Add Poll
          </button>
          ) : (
            userInfo?.permissions[6].add === 1 &&
            userInfo?.permissions[6].access === 1 && (
              <button
              className="btn btn-outline-primary"
              onClick={() => {
                setFormData({
                  question: "",
                  category: "",
                  postId: "",
                  options: {
                    A: { value: "", votes: 0 },
                    B: { value: "", votes: 0 },
                    C: { value: "", votes: 0 },
                    D: { value: "", votes: 0 },
                  },
                  // status: "active",
                });
                setIsEdit(false);
                setShowModal(true);
              }}>
              Add Poll
            </button>
            )
          )} 
        </div>
        {alert.show && (
          <div
            className={`alert alert-${alert.type} alert-dismissible fade show`}
            role="alert">
            {alert.message}
            <button
              type="button"
              className="btn-close"
              onClick={() =>
                setAlert({ show: false, message: "", type: "" })
              }></button>
          </div>
        )}
        <div className="col-12 d-flex justify-content-between mt-3">
          <CSVLink
            data={csvData}
            headers={headers}
            filename={"polls.csv"}
            className=" btn btn-outline-success text-dark"
            target="_blank">
            Download CSV
          </CSVLink>
          <input
            type="text"
            className="form-control w-50"
            placeholder="Search..."
            value={searchTerm}
            onChange={searchFunction}
          />
          <div className="form-group col-4 d-flex align-items-center">
                                <label className="form-label mb-0 me-2 fw-bold">
                                  Rows per page:{" "}
                                </label>
                                <select
                                  className="form-control"
                                  value={rowsPerPage}
                                  onChange={handlePageSizeChange}>
                                  <option value={5}>5</option>
                                  <option value={10}>10</option>
                                  <option value={15}>15</option>
                                  <option value={30}>30</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>

                                </select>
                              </div>
        </div>
        <div className="row m-3">
            <div className="col-12 d-flex justify-content-end fw-bold">
                Showing {rowsPerPage * (currentPage - 1) + 1}-
                {rowsPerPage * currentPage > totalPolls.length
                  ? totalPolls.length
                  : rowsPerPage * currentPage}{" "}
                of {totalPolls.length} Polls
              </div>
            </div>
        <div className="col-12">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "60vh" }}>
              <ClipLoader size={50} color={"#123abc"} loading={loading} />
              <span className="ms-2">Loading...</span>
            </div>
          ) : (
           <>
            <div
              className="table-responsive rounded shadow mt-3"
              style={{ maxHeight: "60vh", overflowY: "auto" }}>
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Category Name</th>
                    <th>Question</th>
                    <th>Options</th>
                    <th>Total Votes</th>
                    {userInfo?.roletype === 0 ? (
                                    <th>Actions</th>
                                  ) : (
                                    (userInfo?.permissions[6].edit === 1 ||
                                      userInfo?.permissions[6].delete === 1) &&
                                    userInfo.permissions[6].access === 1 && (
                                      <th>Actions</th>
                                    )
                                  )}
                  </tr>
                </thead>
                <tbody>
                  {polls.map((poll, index) => (
                    <tr key={index}>
                      <td>
                        {index + 1 + (currentPage - 1) * rowsPerPage}
                      </td>
                      <td>{poll.category}</td>
                      <td>{poll.question}</td>
                      <td>
                        <ul>
                          {Object.values(poll.options).map((option, i) => (
                            <li key={i}>{option.value}</li>
                          ))}
                        </ul>
                      </td>
                      <td>{poll.totalVotes}</td>
                      {userInfo?.roletype === 0 ? (
                                        <td className="d-flex">
                                          <button
                                            className="btn btn-outline-secondary btn-sm m-1"
                                            onClick={(e) => {
                                              e.stopPropagation(); // Prevent row click event
                                              handleEditPoll(poll);
                                            }}>
                                            <i className="bi bi-pencil-square m-1 text-dark"></i>
                                          </button>
                                          <button
                                            className="btn btn-outline-danger btn-sm m-1"
                                            onClick={(e) => {
                                              e.stopPropagation(); // Prevent row click event
                                              handleDelete(poll);
                                            }}>
                                            <i className="bi bi-trash m-1 text-danger ">
                                              {" "}
                                            </i>
                                          </button>
                                        </td>
                                      ) : (
                                        (userInfo?.permissions[6].edit === 1 ||
                                          userInfo?.permissions[6].delete ===
                                            1) &&
                                        userInfo.permissions[6].access ===
                                          1 && (
                                          <td className="d-flex">
                                            {userInfo?.permissions[6].edit ===
                                              1 &&
                                              userInfo?.permissions[6]
                                                .access === 1 && (
                                                <button
                                                  className="btn btn-outline-secondary btn-sm m-1"
                                                  onClick={(e) => {
                                                    e.stopPropagation(); // Prevent row click event
                                                    handleEditPoll(poll);
                                                  }}>
                                                  <i className="bi bi-pencil-square m-1 text-dark"></i>
                                                </button>
                                              )}
                                            {userInfo?.permissions[6].delete ===
                                              1 &&
                                              userInfo?.permissions[6]
                                                .access === 1 && (
                                                <button
                                                  className="btn btn-outline-danger btn-sm m-1"
                                                  onClick={(e) => {
                                                    e.stopPropagation(); // Prevent row click event
                                                    handleDelete(poll);
                                                  }}>
                                                  <i className="bi bi-trash m-1 text-danger ">
                                                    {" "}
                                                  </i>
                                                </button>
                                              )}
                                          </td>
                                        )
                                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row">
                            <div className="col">
                              <div className="d-flex justify-content-center align-content-center m-2">
                                <button
                                  disabled={currentPage === 1}
                                  onClick={() =>
                                    setCurrentPage((prevPage) => prevPage - 1)
                                  }
                                  className="btn btn-outline-info px-3">
                                  Prev
                                </button>
                                <span className="m-1 px-2">
                                  {currentPage}
                                </span>
                                <button
                                  disabled={
                                    currentPage >=
                                    Math.ceil(totalPolls.length / rowsPerPage)
                                  }
                                  onClick={() =>
                                    setCurrentPage((prevPage) => prevPage + 1)
                                  }
                                  className="btn btn-outline-info px-3">
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
           </>
          )}
        </div>
      </div>
      {showModal && (
        <div
          className="modal d-block"
          tabIndex="-1"
          style={{ background: "rgba(0,0,0,0.5)" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{isEdit ? "Edit" : "Add"} Poll</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}></button>
              </div>
              <form onSubmit={handleFormSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="pollQuestion" className="form-label">
                      Question
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pollQuestion"
                      required
                      placeholder="Enter poll question"
                      value={formData.question}
                      onChange={(e) =>
                        setFormData({ ...formData, question: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pollCategory" className="form-label">
                      Category
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pollCategory"
                      required
                      placeholder="Enter poll category"
                      value={formData.category}
                      onChange={(e) =>
                        setFormData({ ...formData, category: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="pollPostId" className="form-label">
                      Post ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pollPostId"
                      required
                      placeholder="Enter post ID"
                      value={formData.postId}
                      onChange={(e) =>
                        setFormData({ ...formData, postId: e.target.value })
                      }
                    />
                  </div>
                  {Object.entries(formData.options).map(
                    ([key, option], index) => (
                      <div className="mb-3" key={index}>
                        <label htmlFor={`option${key}`} className="form-label">
                          Option {key}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id={`option${key}`}
                          required
                          placeholder={`Enter option ${key}`}
                          value={option.value}
                          onChange={(e) => {
                            const updatedOptions = { ...formData.options };
                            updatedOptions[key].value = e.target.value;
                            setFormData({
                              ...formData,
                              options: updatedOptions,
                            });
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setFormData({
                        question: "",
                        category: "",
                        postId: "",
                        options: {
                          A: { value: "", votes: 0 },
                          B: { value: "", votes: 0 },
                          C: { value: "", votes: 0 },
                          D: { value: "", votes: 0 },
                        },
                        // status: "active",
                      });
                      setShowModal(false);
                    }}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Polls;
